<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 card_item p-0">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 pt-3 px-3 offwhite_bg">
            <div class="row m-0 justify-content-center holder">
              <div class=" bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 above">
                <div class="row m-0 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-end">
                      <div class="col-auto text-end pt-1 pe-1 cursor" @click="$emit('close')">
                        <ios-close-icon class="inline-icon big" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 above">
                <div class="row mx-0 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center">
                      <div class="col-auto text-center px-0 pb-3 mb-3">
                        <div class="card_title">
                          Pay Via Bank Transfer
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 above offwhite_bg">
            <div class="row mt-4 justify-content-center text-center">
              <div class="col-7 col-md-3 text-center">
                <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
                <TickAnimation v-else />
              </div>
              <div class="col-12 mb-3 biggest_font text-center bold">
                Jazakallah Khair
              </div>
              <div class="col-12 mb-4 text-center medium green-text-dark">
                You can find this donation under "My Debit Orders" in your Donor Profile.
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end above" >
            <div class="row m-0 background-green py-3 justify-content-center">
              <div class="col-auto">
                <Button color="green_light" width="100" btnText="Finish" icon="arrow" @buttonClicked="finish">
                  <IconArrowForward />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    TickAnimation: defineAsyncComponent(() => import('../components/animations/TickAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Debit Order Complete',
  computed: {
    ...mapGetters([
      'isApp'
    ])
  },
  methods: {
    finish () {
      this.$emit('close')
    }
  }
}

</script>

<style scoped>
.full_popup {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
}

.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color);
}
.bigger_font {
  font-size: 23px;
  color: var(--green-color-dark);
}
.biggest_font {
  font-size: 31px;
  color: var(--green-color-dark);
}
.green-text {
  color: var(--green-color);
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.offwhite_bg {
  background-color: rgb(249, 250, 251);
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: rgb(249, 250, 251);
  /* background-image: linear-gradient(rgba(249, 250, 251, 0.1) 0%, rgb(249, 250, 251) 100%); */
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: url(../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-size: 30% auto;
  opacity: 0.03;
  background-position: top center;
  height: 100%;
  width: 100%;
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.wizard_step {
  border-radius: 50%;
  color: var(--green-color-dark);
  background-color: var(--gold-color);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 11px;
  width: 30px;
  height: 30px;
}
.wizard_step.active {
  background-color: var(--green-color-dark);
  color: var(--gold-color);
}
.wizard_divider {
  border-bottom: 1px solid rgba(104, 103, 103, 0.4);
  width: 15px;
  height: 16px;
}
.small_font {
  font-size: 11px;
  font-family: "quicksand_bold", Sans-serif;
}
.small_text {
  font-size: 13px;
}
.opacity {
  opacity: 0;
}

  .selectFrequency .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    text-align: left;
  }
  .selectFrequency .form-control:focus {
    color: var(--green-color-light);
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .selectFrequency .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--green-color-light);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: 4.5rem;
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
    z-index: 2;
  }

  .selectFrequency .holder {
    position: relative;
  }
  .selectFrequency .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 1.7rem;
    right: 0.7rem;
    z-index: 1;
  }
  .selectFrequency .form-control.gold {
    color: var(--gold-color);
  }

  .selectFrequency .form-control.gold:focus {
    color: var(--gold-color);
  }
  .selectFrequency .big {
    height: 4.5rem;
    border-radius: 4rem;
    text-align: center;
    color: var(--gold-color);
  }
  .selectFrequency .form-control.big:focus {
    color: var(--gold-color);
  }
</style>
